@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'QTGraveure';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/QTGraveure.otf') format('opentype');
}

@font-face {
    font-family: 'QTGraveure';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/QTGraveure-Bold.otf') format('opentype');
}